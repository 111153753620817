.profile {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 350px auto;
  /* align-items: flex-start; */
  gap: 20px;
}

.profile > :first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .profile {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 20px;
  }
}
