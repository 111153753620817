.cat-container {
  z-index: 1;
  background: #e9ecef;
  padding: 8px 0;
  position: sticky;
  top: 0;
  z-index: 111;
}
.category {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #fff;
}

.category > div {
  display: flex;
  overflow: auto;
  gap: 30px;
}

.category > div::-webkit-scrollbar {
  display: none;
}

.cat-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  gap: 8px;
  font-size: 18px;
  padding: 0 25px;
  cursor: pointer;
  color: #0b5dd6;
  background: none;
  border: 1px solid #0b5dd6;
  border-radius: 5px;
  transition: 0.2s ease;
}

.cat-btn.active {
  background: #0b5dd6 !important;
  color: #fff;
}

.cat-btn:hover {
  background: #014fc4 !important;
  color: #fff !important;
}
.cat-btn:active {
  background: #0b5dd6 !important;
}

@media screen and (max-width: 700px) {
  .cat-btn {
    gap: 5px;
    font-size: 16px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .cat-btn {
    height: 30px;
    font-size: 12px;
    padding: 0 15px;
  }
}
