.add-form > label {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #ccc;
  color: #777;
  border-radius: 4px;
  padding: 16px 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid #000;
  }
}

.add-form > label > input {
  display: none;
}
