.modal-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background: #00000048;
  z-index: 10;
  transition: all 0.4s ease;
  top: -100%;
  cursor: pointer;
}

.modal-wrapper.open {
  top: 0;
}

.modal {
  position: absolute;
  left: 50%;
  top: -150%;
  transform: translate(-50%, -50%);
  z-index: 11;
  transition: all 0.4s ease;
  padding: 20px;
}

.modal.open {
  top: 50%;
}

.order-card {
  width: 100%;
  display: flex;
  flex-direction: row;
}
