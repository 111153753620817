.banner {
  width: 100%;
  height: 350px;
  display: grid;
  place-items: center;
  color: #fff;
  background: url(../../../assets/images/food-banner.jpg);
  background-size: cover;
  background-position: center;
}

.banner > div {
  display: grid;
  place-items: center;
  gap: 10px;
}

.banner > div > form {
  width: 100%;
  display: flex;
  gap: 10px;
  padding-top: 20px;
}

.banner > div > h1 {
  font-size: 46px;
}

.banner > div > p {
  font-size: 24px;
}

@media screen and (max-width: 1000px) {
  .banner > div > h1 {
    font-size: 40px;
  }

  .banner > div > p {
    font-size: 18px;
  }

  .banner {
    height: 300px;
  }

  .banner > div > form {
    width: 80%;
  }
}
@media screen and (max-width: 700px) {
  .banner > div > h1 {
    font-size: 34px;
  }

  .banner > div > p {
    font-size: 15px;
  }

  .banner {
    height: 280px;
  }

  .banner > div > form {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .banner > div > h1 {
    font-size: 34px;
  }

  .banner > div > p {
    font-size: 15px;
  }

  .banner {
    height: 280px;
  }

  .banner > div > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .banner > div > h1 {
    display: none;
  }

  .banner > div > p {
    display: none;
  }

  .banner {
    height: auto;
    padding: 20px;
  }

  .banner > div > form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .bn {
    display: none;
  }
}
