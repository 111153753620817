nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 6%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

.list-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.list-items > a {
  padding: 9px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #0b5dd6;
}

.list-items .active {
  background: #0b5dd6;
  color: #fff;
  border: 1px solid #0b5dd6;
}

.side-bar {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 125px);
  padding: 20px;
}

.side-bar > a {
  text-align: center;
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
  font-size: 18px;
  transition: all 0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.side-bar > a.active {
  background: #0b5dd6;
  color: #fff;
}
