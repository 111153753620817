.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
}

.service > .content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 20px;
}

.service > .content > .box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  gap: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.service > .content > .box > h3 {
  color: #000;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

@media screen and (max-width: 1000px) {
  .service > .content {
    grid-template-columns: repeat(2, 1fr);
  }
  .service > .content :nth-child(3) {
    grid-area: 2/1/2/3;
  }
}
@media screen and (max-width: 750px) {
  .service > .content {
    grid-template-columns: 1fr;
  }
  .service > .content :nth-child(3) {
    grid-area: 3;
  }
}
