.cart {
  min-height: calc(100vh - 400px);
  display: grid;
  place-items: center;
}

.cart-container {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 500px;
  padding: 15px 6%;
  gap: 20px;
}

.cart-container > div {
  background: #ffffff;
}

.cart-container > :first-child {
  background: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
}

.cart-container > :last-child {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: #ffffff;
}

.cart-container > div > .cart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.cart-container > div > .cart-box > :first-child {
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
}

.cart-container > div > .cart-box > div > figure {
  width: 60px;
}

.message {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.total-price-card {
  display: flex;
}

@media screen and (max-width: 1000px) {
  .cart-container {
    grid-template-columns: 1fr !important;
    grid-template-rows: 500px;
    padding: 15px 6%;
    gap: 20px;
  }

  .cart-box > div > h3 {
    max-width: 200px;
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .cart-box {
    min-width: 500px;
  }
}
