.dashboard-container {
  width: 100%;
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: auto;
}

.route {
  width: 100%;
  min-height: 70px;
  background: linear-gradient(
    90deg,
    rgba(0, 68, 168, 1) 0%,
    rgba(0, 166, 255, 1) 100%
  );

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: -20px;
  color: #fff;
  padding: 15px;
  z-index: 1;
}

.route > button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #0b5dd6;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: capitalize;
}

.route > button:hover {
  background: #0b5cd68d;
}

.route > button:active {
  background: #0b5dd6;
}

.card-container {
  width: 100%;
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
}

.card {
  display: flex;
  height: 150px;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
}

.card > h1 {
  text-align: center;
}
.card > h2 {
  text-align: center;
}

.card > h1 > a {
  text-decoration: none;
  text-align: center;
  color: #191919;
  transition: all 0.5s ease;
}

.card > h1 > a:hover {
  text-decoration: none;
  color: #191919a7;
}

.card > h1 {
  min-width: 60%;
}

.card > h2 {
  min-width: 20%;
}

@media (max-width: 1200px) {
  .card-container {
    width: 100%;
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 1000px) {
  .card-container {
    width: 100%;
    font-size: 20px;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 600px) {
  .card {
    display: flex;
    height: 100px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }
}
